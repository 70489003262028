import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebaseConfig'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vClickOutside from 'v-click-outside'
import VueMask from 'v-mask'
import moment from 'moment'

Vue.filter('moment', function (value, format) {
  if (value === null || value === undefined || value == '' || format === undefined) {
      return ''
  }
  if (format === 'from') {
      return moment(value).fromNow()
  }
  return moment(value).format(format)
})

Vue.filter('truncate', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

import './assets/sass/main.scss'
const fb = require('./firebaseConfig.js')

Vue.use(VueGoodTablePlugin)
Vue.use(vClickOutside)
Vue.use(VueMask)
Vue.component('v-select', vSelect)
Vue.use(require('vue-moment'))

Vue.config.productionTip = false
Vue.config.devtools = true

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})