import Vue from 'vue'
import Vuex from 'vuex'
const fb = require('../firebaseConfig.js')
import router from '../router/index'
import firebase from 'firebase/app';
const db = getFirestore();
import { getFirestore, getDocs, getDoc, doc, onSnapshot, collection, query, where, setDoc, addDoc, Timestamp, updateDoc, serverTimestamp, deleteDoc, orderBy, limit } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile', user)
  }
})



const store = new Vuex.Store({
  state: {
    userProfile: {},
    currentUser: null,
    vehicles: [],
    dpsVehicles: [],
    vehicleInfo: {},
    vehicleTickets: [],
    vehicleRecommendations: [],
    tickets: [],
    ticketInfo: {},
    ticketsTotal: [],
    ticketsTotalNext: [],
    accounts: [],
    accountInfo: {},
    ticketMessages: [],
    ticketInvoices: [],
    invoices: [],
    invoiceInfo: {},
    messages: [],
    messageInfo: {},
    users: [],
    userInfo: {},
    accountUsers: [],
    accountVehicles: [],
    accountInvoices: [],
    accountTickets: [],
    recommendations: [],
  },
  actions: {
    async login({ dispatch, commit }, form) {
      // sign user in

      const auth = getAuth();
      signInWithEmailAndPassword(auth, form.email, form.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          store.dispatch('fetchUserProfile', user)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },
    // async fetchUserProfile({ commit }, user) {
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    //     const docSnap = await getDoc(docRef);
    //     if(docSnap.exists()) {
    //         console.log(docSnap.data());
    //         commit('setUserProfile', docSnap.data())
    //         store.dispatch('getVehicles')
    //         store.dispatch('getTickets')
    //     } else {
    //         console.log("Document does not exist")
    //     }

    //   } catch(error) {
    //       console.log(error)
    //   }
    // },
    async fetchUserProfile({ commit }, user) {
      try {
        const docRef = doc(db, "admins", user.uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            console.log(docSnap.data());
            commit('setUserProfile', docSnap.data())
            store.dispatch('getVehicles')
            store.dispatch('getTickets')
            store.dispatch('getAccounts')
            store.dispatch('getMessages')
            store.dispatch('getInvoices')
            store.dispatch('getUsers')
        } else {
            console.log("Document does not exist")
            store.dispatch('logout')
        }

      } catch(error) {
          console.log(error)
      }
    },
    async logout({ commit }) {
      // log user out
      const auth = getAuth();

      await auth.signOut()
      // clear user data from state
      commit('setUserProfile', {})
      commit('setInvoices', null)
      commit('setVehicles', null)
      commit('setDPSVehicles', null)
      commit('setTickets', null)
      commit('setMessage', null)
      commit('setAccounts', null)
      commit('setCurrentUser', null)
      // redirect to login view
      router.push('/login')
    },


    /*RECOMMENDATIONS*/
    async addRecommendation({ commit }, payload) {
      console.log(payload)
      const recommendationRef = addDoc(collection(db, "recommendations"), payload)
      .then(
        doc => {
          store.dispatch('addRecId', doc.id)
        }
      )
    },
    async addRecId({ commit }, payload) {
      console.log(payload)
      const recommendationsRef = await doc(db, "recommendations", payload)
      updateDoc(recommendationsRef, {
        id: payload,
        created: serverTimestamp()
      });
      store.dispatch('getRecommendations')
    },
    async getRecommendations({ commit }, payload) {
      const querySnapshot = await getDocs(collection(db, "recommendations"));
      let recsArray = []
      querySnapshot.forEach((doc) => {
        let rec = doc.data()
        recsArray.push(rec)
      });
      commit('setRecommendations', recsArray)
    },
    clearRecommendations({ commit }) {
      commit('setRecommendations', [])
    },


    /*INVOICES*/
    async getTicketInvoices({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "invoices"), where("ticket.id", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let invoicesArray = []
        querySnapshot.forEach((doc) => {
          let invoice = doc.data()
          invoicesArray.push(invoice)
        });
        commit('setTicketInvoices', invoicesArray)
      });
    },
    async getInvoices({ commit }) {
      const q = query(collection(db, "invoices"),  orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let invoicesArray = []
        querySnapshot.forEach((doc) => {
          let invoice = doc.data()
          invoicesArray.push(invoice)
        });
        commit('setInvoices', invoicesArray)
      });
    },
    async getDPSInvoices({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "invoices"), where("dps", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let invoicesArray = []
        querySnapshot.forEach((doc) => {
          let invoice = doc.data()
          invoicesArray.push(invoice)
        });
        commit('setDPSInvoices', invoicesArray)
      });
    },
    async getInvoiceFromId({ commit }, payload) {
      const docRef = await doc(db, "invoices", payload);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        commit("setInvoiceInfo", docSnap.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async updateInvoice({ commit }, payload) {
      console.log(payload)
      const invoiceRef = doc(db, "invoices", payload.id)
      await updateDoc(invoiceRef, payload);
    },
    async deleteInvoice({ commit }, payload) {
      await deleteDoc(doc(db, "invoices", payload.id));
    },
    clearInvoiceInfo({ commit }) {
      commit("setInvoiceInfo", {})
    },


    /* USERS */
    async getUsers({ commit }) {
      const querySnapshot = await getDocs(collection(db, "users"));
        let usersArray = []
        querySnapshot.forEach((doc) => {
          let user = doc.data()
          usersArray.push(user)
        });
        commit('setUsers', usersArray)
    },
    async getUserFromId({ commit }, payload) {
      const docRef = await doc(db, "users", payload);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        commit("setUserInfo", docSnap.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async updateUser({ commit }, payload) {
      console.log(payload)
      const userRef = doc(db, "users", payload.id)
      await updateDoc(userRef, payload);
    },
    async deleteUser({ commit }, payload) {
      await deleteDoc(doc(db, "users", payload.id));
    },
    clearUserInfo({ commit }) {
      commit("userInfo", {})
    },





    /*MESSAGES*/
    async getMessages({ commit }, payload) {
      const querySnapshot = await getDocs(collection(db, "ticketMessages"), orderBy("created", "desc"));
      let messagesArray = []
      querySnapshot.forEach((doc) => {
        let message = doc.data()
        messagesArray.push(message)
      });
      commit('setMessages', messagesArray)
    },
    async getMessageFromId({ commit }, payload) {
      const docRef = await doc(db, "ticketMessages", payload);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        commit("setMessageInfo", docSnap.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async updateMessage({ commit }, payload) {
      console.log(payload)
      const messageRef = doc(db, "ticketMessages", payload.id)
      await updateDoc(messageRef, payload);
    },
    async deleteMessage({ commit }, payload) {
      await deleteDoc(doc(db, "ticketMessages", payload.id));
    },
    clearMessageInfo({ commit }) {
      commit("messageInfo", {})
    },
    async sendTicketMessage({ commit }, payload) {
      console.log(payload)
      const docRef = addDoc(collection(db, "ticketMessages"), payload)
     .then(
        doc => {
          store.dispatch('addTicketMessageId', doc.id)
          // store.dispatch('getTicketMessages', payload.ticket)
        }
      )
     store.dispatch('getMessages')
    },
    async addTicketMessageId({ commit }, payload) {
      console.log(payload)
      const messageRef = await doc(db, "ticketMessages", payload)

      updateDoc(messageRef, {
        status: 'New',
        id: payload,
        created: serverTimestamp()
      })
    },
    async getTicketMessages({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "ticketMessages"), where("ticket", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let messagesArray = []
        querySnapshot.forEach((doc) => {
          let message = doc.data()
          messagesArray.push(message)
        });
        commit('setTicketMessages', messagesArray)
      });
    },



    /*TICKETS*/

    async getTicketsTotal({ commit }) {
      const querySnapshot = await getDocs(collection(db, "tickets"))     
      console.log(querySnapshot.size);
      commit('setTicketsTotal', querySnapshot.size)
      commit('setTicketsTotalNext', (querySnapshot.size + 2400))
    },

    async getTickets({ commit }, payload) {
      const querySnapshot = await getDocs(collection(db, "tickets"));
      let ticketsArray = []
      querySnapshot.forEach((doc) => {
        let ticket = doc.data()
        ticketsArray.push(ticket)
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      commit('setTickets', ticketsArray)
    },


    // addTicket({ commit }, payload) {
    //  const docRef = addDoc(collection(db, "tickets"), payload)
    //  .then(
    //     doc => {
    //       store.dispatch('addTicketId', doc.id)
    //     }
    //   )
    // },
    // async addTicketId({ commit }, payload) {
    //   console.log(payload)
    //   const ticketRef = await doc(db, "tickets", payload)
    //   updateDoc(ticketRef, {
    //     status: 'Open',
    //     id: payload,
    //     created: serverTimestamp()
    //   });
    //   store.dispatch('getTickets')
    // },
    async getTicketFromId({ commit }, payload) {
      console.log(payload)
      const docRef = doc(db, "tickets", payload);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        commit("setTicketInfo", docSnap.data())
        store.dispatch('getTicketMessages', docSnap.id)
        store.dispatch('getTicketInvoices', docSnap.id)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async updateTicket({ commit }, payload) {
      console.log(payload)
      const ticketRef = doc(db, "tickets", payload.id)
      await updateDoc(ticketRef, payload);
      store.dispatch('getTickets')
    },
    async deleteTicket({ commit }, payload) {
      await deleteDoc(doc(db, "tickets", payload.id));
      store.dispatch('getTickets')
    },
    clearTicket({ commit }) {
      commit("setTicketInfo", {})
      commit('setTicketMessages',[])
      commit('setTicketInvoices',[])
    },
    clearTickets({ commit }) {
      commit('setTickets', [])
    },



    /*VEHICLES*/
    async getVehicles({ commit }, payload) {
      const querySnapshot = await getDocs(collection(db, "vehicles"));
      let vehiclesArray = []
      querySnapshot.forEach((doc) => {
        let vehicle = doc.data()
        vehiclesArray.push(vehicle)
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      commit('setVehicles', vehiclesArray)
    },


    async getDPSVehicles({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "vehicles"), where("dps", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let vehiclesArray = []
        querySnapshot.forEach((doc) => {
          let vehicle = doc.data()
          if (!vehicle.deleted) {
            vehiclesArray.push(vehicle)
        } else {}
        });
        commit('setDPSVehicles', vehiclesArray)
      });
    },


    addVehicle({ commit }, payload) {
     const docRef = addDoc(collection(db, "vehicles"), payload)
     .then(
        doc => {
          store.dispatch('addVehicleId', doc.id)
        }
      )
    },
    addVehicleId({ commit }, payload) {
      console.log(payload)
      const vehicleRef = doc(db, "vehicles", payload)
      updateDoc(vehicleRef, {
        id: payload,
        created: serverTimestamp()
      });
      store.dispatch('getVehicles')
    },
    async updateVehicle({ commit }, payload) {
      console.log(payload)
      const vehicleRef = doc(db, "vehicles", payload.id)
      await updateDoc(vehicleRef, payload);
      store.dispatch('getVehicles')
    },
    async deleteVehicle({ commit }, payload) {
      await deleteDoc(doc(db, "vehicles", payload.id));
      store.dispatch('getVehicles')
    },
    async getVehicleFromId({ commit }, payload) {
      console.log(payload)
      const docRef = doc(db, "vehicles", payload);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        commit("setVehicleInfo", docSnap.data())
        store.dispatch('getVehicleTickets', docSnap.id)
        store.dispatch('getVehicleRecommendations', docSnap.id)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async getVehicleTickets({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "tickets"), where("vehicleId", "==", payload));
      const querySnapshot = await getDocs(q)
      // const querySnapshot = await getDocs(collection(db, "tickets"), where("vehicleId", "===", payload));
      let ticketsArray = []
      querySnapshot.forEach((doc) => {
        
        let ticket = doc.data()
        
        ticketsArray.push(ticket)
        // if (!ticket.deleted) {
        //   ticketsArray.push(ticket)
        // } else {}
      });
      commit('setVehicleTickets', ticketsArray)
    },
    async getVehicleRecommendations({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "recommendations"), where("vehicleId", "==", payload));
      const querySnapshot = await getDocs(q)
      // const querySnapshot = await getDocs(collection(db, "tickets"), where("vehicleId", "===", payload));
      let recommendationsArray = []
      querySnapshot.forEach((doc) => {
        
        let recommendation = doc.data()
        
        recommendationsArray.push(recommendation)
        // if (!recommendation.deleted) {
        //   recommendationsArray.push(recommendation)
        // } else {}
      });
      commit('setVehicleRecommendations', recommendationsArray)
    },
    clearVehicle({ commit }) {
      commit("setVehicleInfo", {})
      commit('setVehicleTickets', [])
      commit('setVehicleRecommendations', [])
    },
    clearVehicles({ commit }) {
      commit('setVehicles', [])
    },

    /*ACCOUNTS*/
    addDPS({ commit }, payload) {
     const docRef = addDoc(collection(db, "dps"), payload)
     .then(
        doc => {
          store.dispatch('addDPSId', doc.id)
        }
      )
    },
    addDPSId({ commit }, payload) {
      console.log(payload)
      const dpsRef = doc(db, "dps", payload)
      updateDoc(dpsRef, {
        id: payload,
        created: serverTimestamp()
      });
      store.dispatch('getAccounts')
    },
    async getAccounts({ commit }, payload) {
      const querySnapshot = await getDocs(collection(db, "dps"));
      let accountsArray = []
      querySnapshot.forEach((doc) => {
        let account = doc.data()
        accountsArray.push(account)
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      commit('setAccounts', accountsArray)
    },
    async getAccountFromId({ commit }, payload) {
      console.log(payload)
      const docRef = doc(db, "dps", payload);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("document!");
        commit("setAccountInfo", docSnap.data())
        store.dispatch('getAccountUsers', docSnap.id)
        store.dispatch('getAccountInvoices', docSnap.id)
        store.dispatch('getAccountTickets', docSnap.id)
        store.dispatch('getAccountVehicles', docSnap.id)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async getAccountTickets({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "tickets"), where("dps", "==", payload));
      const uu = onSnapshot(q, (querySnapshot) => {
      let ticketsArray = []
        querySnapshot.forEach((doc) => {
        let ticket = doc.data()
        ticketsArray.push(ticket)
        })
        commit('setAccountTickets', ticketsArray)
      })
    },
    async getAccountUsers({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "users"), where("dpsId", "==", payload));
      const uu = onSnapshot(q, (querySnapshot) => {
      let usersArray = []
        querySnapshot.forEach((doc) => {
        let user = doc.data()
        usersArray.push(user)
        })
        commit('setAccountUsers', usersArray)
      })
    },
    async getAccountInvoices({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "invoices"), where("dps", "==", payload));
      const uu = onSnapshot(q, (querySnapshot) => {
      let invoicesArray = []
        querySnapshot.forEach((doc) => {
        let invoice = doc.data()
        invoicesArray.push(invoice)
        })
        commit('setAccountInvoices', invoicesArray)
      })
    },
    async getAccountVehicles({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "vehicles"), where("dps", "==", payload));
      const uu = onSnapshot(q, (querySnapshot) => {
      let vehiclesArray = []
        querySnapshot.forEach((doc) => {
        let vehicle = doc.data()
        vehiclesArray.push(vehicle)
        })
        commit('setAccountVehicles', vehiclesArray)
      })
    },
    
    clearAccount({ commit }) {
      commit("setAccountInfo", {})
      commit('setAccountUsers', [])
      commit('setAccountInvoices', [])
      commit('setAccountTickets', [])
      commit('setAccountVehicles', [])
      // commit('setTicketMessages',[])
      // commit('setTicketInvoices',[])
    },
    clearAccounts({ commit }) {
      commit('setAccounts', [])
    },

  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setVehicleInfo(state, val) {
      state.vehicleInfo = val
    },
    setVehicles(state, val) {
      if (val) {
        state.vehicles = val
      } else {
        state.vehicles = []
      }
    },
    setDPSVehicles(state, val) {
      if (val) {
        state.dpsVehicles = val
      } else {
        state.dpsVehicles = []
      }
    },
    setTicketsTotal(state, val) {
      state.ticketsTotal = val
    },
    setTicketsTotalNext(state, val) {
      state.ticketsTotalNext = val
    },
    setTicketInfo(state, val) {
      state.ticketInfo = val
    },
    setUserInfo(state, val) {
      state.userInfo = val
    },
    setTickets(state, val) {
      if (val) {
        state.tickets = val
      } else {
        state.tickets = []
      }
    },
    setAccounts(state, val) {
      if (val) {
        state.accounts = val
      } else {
        state.accounts = []
      }
    },
    setAccountInfo(state, val) {
      state.accountInfo = val
    },
    setAccountUsers(state, val) {
      if (val) {
        state.accountUsers = val
      } else {
        state.accountUsers = []
      }
    },
    setAccountInvoices(state, val) {
      if (val) {
        state.accountInvoices = val
      } else {
        state.accountInvoices = []
      }
    },
    setAccountVehicles(state, val) {
      if (val) {
        state.accountVehicles = val
      } else {
        state.accountVehicles = []
      }
    },
    setAccountTickets(state, val) {
      if (val) {
        state.accountTickets = val
      } else {
        state.accountTickets = []
      }
    },
    setVehicleTickets(state, val) {
      if (val) {
        state.vehicleTickets = val
      } else {
        state.vehicleTickets = []
      }
    },
    setVehicleRecommendations(state, val) {
      if (val) {
        state.vehicleRecommendations = val
      } else {
        state.vehicleRecommendations = []
      }
    },
    setMessages(state, val) {
      if (val) {
        state.messages = val
      } else {
        state.messages = []
      }
    },
    setMessageInfo(state, val) {
      state.messageInfo = val
    },
    setTicketMessages(state, val) {
      if (val) {
        state.ticketMessages = val
      } else {
        state.ticketMessages = []
      }
    },
    setTicketInvoices(state, val) {
      if (val) {
        state.ticketInvoices = val
      } else {
        state.ticketInvoices = []
      }
    },
    setInvoices(state, val) {
      if (val) {
        state.invoices = val
      } else {
        state.invoices = []
      }
    },
    setInvoiceInfo(state, val) {
      state.invoiceInfo = val
    },
    setUsers(state, val) {
      if (val) {
        state.users = val
      } else {
        state.users = []
      }
    },
    setRecommendations(state, val) {
      if (val) {
        state.recommendations = val
      } else {
        state.recommendations = []
      }
    },
  },
})

export default store
