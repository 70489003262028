import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebaseConfig'
import store from '../store';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/', redirect: '/workorders',
      name: 'dashboard',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/inbox',
      name: 'messages',
      component: () => import('../views/Messages/Messages.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/inbox/:id',
      name: 'message',
      component: () => import('../views/Messages/Message.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/invoices', redirect: '/invoices/all',
      name: 'invoicesHome',
      component: () => import('../views/Invoices/InvoicesHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/invoices/all',
          name: 'invoices',
          component: () => import('../views/Invoices/Invoices.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/invoices/all/:id',
          name: 'invoice',
          component: () => import('../views/Invoices/Invoice.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/invoices/add',
          name: 'addInvoice',
          component: () => import('../views/Invoices/AddInvoice.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    // {
    //   path: '/invoices',
    //   name: 'invoices',
    //   component: () => import('../views/Invoices/Invoices.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    // {
    //   path: '/invoices/:id',
    //   name: 'invoice',
    //   component: () => import('../views/Invoices/Invoice.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/accountsHome', redirect: '/accounts',
      name: 'accountsHome',
      component: () => import('../views/Accounts/AccountsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/accounts',
          name: 'accounts',
          component: () => import('../views/Accounts/Accounts.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('../views/Accounts/Users.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/users/:id',
          name: 'user',
          component: () => import('../views/Accounts/User.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/addaccount',
          name: 'addAccount',
          component: () => import('../views/Accounts/AddAccount.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/addaccess',
          name: 'addAccountAccess',
          component: () => import('../views/Accounts/AddAccountAccess.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
        path: '/accountsHome', redirect: '/accounts/:id',
          name: 'accountsHome',
          component: () => import('../views/Accounts/Account/AccountHome.vue'),
          meta: {
            requiresAuth: true,
            refreshAccount: true
          },
          children: [
            {
              path: '/accounts/:id',
              name: 'account',
              component: () => import('../views/Accounts/Account/Account.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/invoices',
              name: 'accountInvoices',
              component: () => import('../views/Accounts/Account/AccountInvoices.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/invoices/:iv',
              name: 'accountInvoice',
              component: () => import('../views/Accounts/Account/AccountInvoice.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/tickets',
              name: 'accountTickets',
              component: () => import('../views/Accounts/Account/AccountTickets.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/tickets/:it',
              name: 'accountTicket',
              component: () => import('../views/Accounts/Account/AccountTicket.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/vehicles',
              name: 'accountVehicles',
              component: () => import('../views/Accounts/Account/AccountVehicles.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/vehicles/:ic',
              name: 'accountVehicle',
              component: () => import('../views/Accounts/Account/AccountVehicle.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/users',
              name: 'accountUsers',
              component: () => import('../views/Accounts/Account/AccountUsers.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: '/accounts/:id/users/:iu',
              name: 'accountUser',
              component: () => import('../views/Accounts/Account/AccountUser.vue'),
              props: true,
              meta: {
                requiresAuth: true
              },
            },
          ]
        }
      ]
    },
    // {
    //   path: '/vehicles',
    //   name: 'vehicles',
    //   component: () => import('../views/Vehicles.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    // {
    //   path: '/vehicles/:id',
    //   name: 'vehicle',
    //   component: () => import('../views/Vehicle.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    // {
    //   path: '/addvehicle',
    //   name: 'addVehicle',
    //   component: () => import('../views/AddVehicle.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    // },
    {
      path: '/tickets', redirect: '/workorders',
      name: 'ticketsHome',
      component: () => import('../views/Tickets/TicketsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/workorders',
          name: 'tickets',
          component: () => import('../views/Tickets/Tickets.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/workorders/:id',
          name: 'ticket',
          component: () => import('../views/Tickets/Ticket.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/addticket',
          name: 'addTicket',
          component: () => import('../views/Tickets/AddTicket.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/fleet', redirect: '/fleet/vehicles',
      name: 'fleetHome',
      component: () => import('../views/Fleet/FleetHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/fleet/vehicles',
          name: 'vehicles',
          component: () => import('../views/Fleet/Vehicles.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/fleet/vehicles/:id',
          name: 'vehicle',
          component: () => import('../views/Fleet/Vehicle.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/fleet/addvehicle',
          name: 'addVehicle',
          component: () => import('../views/Fleet/AddVehicle.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Auth/Login.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  let currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  let refreshAccount = to.matched.some(x => x.meta.refreshAccount)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } 
  else if (!requiresAuth && currentUser) next();
  else 
  {
    next()
  }

  if (refreshAccount && (to.name == 'account' || to.name == 'accountHome' || to.name == 'accountInvoices' || to.name == 'accountTickets' || to.name == 'accountTicket'  || to.name == 'accountInvoice' || to.name == 'accountVehicles' || to.name == 'accountVehicle'))
  {
    console.log('not clearing group')
  
  } else if (refreshAccount) {
    this.$store.dispatch('clearAccount')
  } else {

  }
})

export default router