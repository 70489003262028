<template>
  <div id="app">
    <Nav v-if="(userProfile && userProfile.id)" :userProfile="(userProfile && userProfile.id)" />
    <!-- <keep-alive  max="3" :include="['home']">
      <router-view :key="$route.fullPath"></router-view> 
    </keep-alive> -->
    <router-view :key="$route.fullPath"></router-view> 
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Nav from '@/components/Nav'
// import Nav from '@/components/Nav'
// import Footer from '@/components/Footer'
import router from '@/router'

export default {
  components: {
    Nav,
    // Footer,
  },
  // watch:{
  //   $route (to, from){
  //     this.$store.dispatch('clearErrors')
  //   }
  // },
  // methods: {
  //   close() {
  //     this.$store.dispatch('clearErrors')
  //   },
  // },
  // mounted () {
  //   this.$store.dispatch("getInfiniteEvents")
  //   this.$store.dispatch("getVenues")
  // },

  // created () {
  //   if (!this.userProfile || !this.userProfile.id) {
  //     this.$store.dispatch("getUserProfile");
  //   }
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    // showBar() {
    //   if (!this.userProfile || !this.userProfile.id) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // showNav() {
    //   return Object.keys(this.userProfile).length > 1
    // },
    // isHome() {
    //   if (this.$route.name == 'home') {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  // metaInfo: () => ({
  //   title: 'Jump',
  //   titleTemplate: '%s | On-Demand Staffing Platform',
  //   meta: [{
  //     vmid: 'description',
  //     name: 'description',
  //     content: 'On-demand staffing for live events, hotels, conventions, warehouses, and more',
  //   }],
  // })
}
</script>